import { ChangeEvent, useState } from 'react';
import { useActiveConfigValues } from '../../../../hooks/useActiveConfigValues';
import { Image } from '@newco-widgets/types';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';

function getImageArray(images: Image[] | undefined, length: number) {
    return Array.from({ length }).map((value, index) => ({
        id: index,
        src: images?.at(index)?.src ?? '',
        alt: images?.at(index)?.alt ?? '',
    }));
}

export function MultiImagePicker({ propName }: BasePickerProps) {
    const updateProperty = useUpdatePropInConfiguration(propName);
    const { activeWidget } = useActiveConfigValues();
    const images = activeWidget?.widgetData[propName] as Image[] | undefined;
    const [length, setLength] = useState(images?.length ?? 0);
    const imageArray = getImageArray(images, length);

    function onImageChange(
        event: ChangeEvent<HTMLInputElement>,
        id: number,
        updateKey: 'src' | 'alt'
    ) {
        const value = event.target.value;
        if (updateKey === 'src') imageArray[id].src = value;
        else imageArray[id].alt = value;
        const images = imageArray.map(({ id, ...image }) => image);

        updateProperty(images);
    }

    return (
        <div className="space-y-5">
            {imageArray.map(({ id }) => (
                <div key={id} className="space-y-3">
                    <label
                        className="inline-block text-sm font-semibold"
                        htmlFor={`image-single-source-${id + 1}`}
                    >
                        Image {id + 1} Source
                    </label>
                    <input
                        className="block w-full rounded-lg border p-2.5"
                        id={`image-single-source-${id + 1}`}
                        placeholder={`Enter image ${id + 1} alt text here...`}
                        value={imageArray[id].src}
                        onChange={(event) => {
                            onImageChange(event, id, 'src');
                        }}
                    />
                    <label
                        className="inline-block text-sm font-semibold"
                        htmlFor={`image-single-${id + 1}`}
                    >
                        Image {id + 1} ALT Text
                    </label>
                    <input
                        className="block w-full rounded-lg border p-2.5"
                        id={`image-single-${id + 1}`}
                        placeholder={`Enter image ${id + 1} alt text here...`}
                        value={imageArray[id].alt}
                        onChange={(event) => {
                            onImageChange(event, id, 'alt');
                        }}
                    />
                </div>
            ))}
            <div className="text-center">
                <button
                    className="rounded bg-teal-600 p-2 text-white"
                    type="button"
                    onClick={() => setLength(length + 1)}
                >
                    New Image Block
                </button>
            </div>
        </div>
    );
}
