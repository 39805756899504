import format from 'date-fns/format';

export function FormScheduleFields() {
    return (
        <>
            <div className="flex flex-col space-y-3">
                <label className="text-sm font-semibold" htmlFor="start-time">
                    Start Time
                </label>
                <input
                    required
                    className="rounded-lg border p-2.5 text-sm"
                    id="start-time"
                    min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                    name="start-time"
                    type="datetime-local"
                />
            </div>
            <div className="flex flex-col space-y-3">
                <label className="text-sm font-semibold" htmlFor="end-time">
                    End Time
                </label>
                <input
                    required
                    className="rounded-lg border p-2.5 text-sm"
                    id="end-time"
                    min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                    name="end-time"
                    type="datetime-local"
                />
            </div>
        </>
    );
}
