// 3P Dependencies
import { useNavigate, useParams } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

// Hooks Dependencies
import { usePageTemplate } from '../../../../hooks/usePageTemplate';
import { useBasePath } from '../../../../hooks/useBasePath';

export function ActiveSlotSelector() {
    const navigate = useNavigate();
    const params = useParams();
    const basePath = useBasePath();
    const { data: pageTemplate, isLoading, isError, error } = usePageTemplate();

    if (isLoading) {
        return (
            <div className="flex justify-center">
                <FaSpinner className="animate-spin" size={22} />;
            </div>
        );
    }

    if (isError) {
        const {
            response: {
                data: {
                    message = 'Some error has been occurred in page template',
                } = {},
            } = {},
        } = error;
        return (
            <div
                className="mb-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
                role="alert"
            >
                {message}
            </div>
        );
    }

    return (
        <div className="flex items-center space-x-3">
            <label className="font-semibold">Active Slot: </label>
            <select
                className="flex-1 rounded-lg border px-2.5 py-2 text-sm"
                value={params.slotId}
                onChange={(event) => {
                    const slotId = event.target.value;
                    navigate(`${basePath}/${params.pageId}/${slotId}`);
                }}
            >
                {pageTemplate?.slots?.map((slot) => (
                    <option key={slot} value={slot}>
                        {slot}
                    </option>
                ))}
            </select>
        </div>
    );
}
