import { useState } from 'react';
import { AddNewWidgetModal } from './AddNewWidgetModal';

export function AddNewWidgetSection() {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <button
                className="w-full space-x-5 rounded-lg bg-teal-600 py-2 px-4 text-white"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Add New Widget
            </button>
            <AddNewWidgetModal
                setShowModal={setShowModal}
                showModal={showModal}
            />
        </>
    );
}
