import { rest } from 'msw';
import {
    ILogin,
    IUserAuthorization,
    IUserDetails,
} from '../utils/models/user.model';
import { AUTHORIZE_INFO, LOGIN_INFO, USER_INFO } from './data/loginInfo';

export const handlers = [
    // User Login
    rest.post<ILogin>('/login', (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(LOGIN_INFO));
        // return res(
        //   ctx.status(500),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description: "Something went wrong. please try after sometime",
        //   })
        // );
        // return res(
        //   ctx.status(401),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description:
        //       "Invalid Credentials",
        //   })
        // );
        // return res(
        //   ctx.status(400),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description: "Invalid Request",
        //   })
        // );
    }),
    // User Authentication
    rest.post<IUserAuthorization>('/user/authorize', (req, res, ctx) => {
        const isAuthenticated = localStorage.getItem('token');
        if (!isAuthenticated) {
            return res(
                ctx.status(401),
                ctx.json({
                    errorCode: 'TSUE-1XX',
                    description: 'Unauthenticated',
                })
            );
        }
        return res(ctx.status(200), ctx.json(AUTHORIZE_INFO));
        // return res(
        //   ctx.status(500),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description: "Something went wrong. please try after sometime",
        //   })
        // );
        // return res(
        //   ctx.status(403),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description:
        //       "Unauthorized: you have not proper access to perform this operation",
        //   })
        // );
        // return res(
        //   ctx.status(400),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description: "Invalid Request",
        //   })
        // );
    }),
    // User Info
    rest.get<IUserDetails>('/user/:userId', (req, res, ctx) => {
        return res(
            ctx.set({
                'Content-Type': 'application/json',
            }),
            ctx.status(200),
            ctx.json(USER_INFO)
        );
        // return res(
        //   ctx.status(500),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description: "Something went wrong. Please try after sometime",
        //   })
        // );
        // return res(
        //   ctx.status(403),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description:
        //       "Unauthorized: you have not proper access to perform this operation",
        //   })
        // );
        // return res(
        //   ctx.status(400),
        //   ctx.json({
        //     errorCode: "TSUE-1XX",
        //     description: "Invalid Request",
        //   })
        // );
    }),
];
