import { FC, ReactElement } from 'react';
import { Carousel } from '@newco-widgets/carousel';
import { Banner } from '@newco-widgets/banner';
import { Card } from '@newco-widgets/card';
import { usePagePreview } from '../../../hooks/usePagePreview';
import { getWidgetName } from './WidgetList';
import { WidgetType } from '@newco-widgets/types';
import { DateTimePicker } from './DateTimePicker';
import { FaSpinner } from 'react-icons/fa';

/**
 * A mapping of component to render for each widget type.
 */
const WidgetComponentMapping: Record<WidgetType, FC<ReactElement['props']>> = {
    carousel: Carousel,
    banner: Banner,
    card: Card,
};

export function Preview() {
    const { data: page, isLoading, isError, error } = usePagePreview();

    if (isLoading)
        return (
            <div className="flex h-screen items-center justify-center">
                <FaSpinner className="animate-spin" size={22} />
                <span className="pl-2">Loading...</span>
            </div>
        );

    if (isError) {
        const defaultMessage = 'Some error has been occurred in preview';
        const message = error.response?.data?.message ?? defaultMessage;

        return (
            <div
                className="mb-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
                role="alert"
            >
                {message}
            </div>
        );
    }

    const widgetsFromSlots = page?.slots.map((slot) => page?.widgets[slot]);
    return (
        <div className="space-y-10">
            <DateTimePicker />
            {widgetsFromSlots?.map((widget) => {
                const widgetName = getWidgetName(widget?.widgetId ?? '');
                const WidgetComponent = WidgetComponentMapping[widgetName];
                if (!WidgetComponent) return null;
                return (
                    <WidgetComponent
                        key={widget.widgetId}
                        {...widget.widgetData}
                    />
                );
            })}
        </div>
    );
}
