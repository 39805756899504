import { Dispatch, SetStateAction } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'ui';
import { AddNewWidgetFormBody } from './AddNewWidgetFormBody';

interface AddNewWidgetModalProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}
export function AddNewWidgetModal({
    showModal,
    setShowModal,
}: AddNewWidgetModalProps) {
    const rootElement = document.querySelector('body');
    if (!rootElement || !showModal) return null;

    return createPortal(
        <Modal isOpen={showModal} setOpen={setShowModal}>
            <div className="space-y-7">
                <h1 className="text-xl font-semibold">Add New Widget</h1>
                <AddNewWidgetFormBody setShowModal={setShowModal} />
            </div>
        </Modal>,
        rootElement
    );
}
