import { useQueryClient } from '@tanstack/react-query';
import { useActiveConfigValues } from '../../../hooks/useActiveConfigValues';
import { useSchedules } from '../../../hooks/useSchedules';
import ReactDragListView from 'react-drag-listview';
import { SlotWidgetItem } from './SlotWidgetItem';
import { FaSpinner } from 'react-icons/fa';

export function SlotWidgets() {
    const queryClient = useQueryClient();
    const { activeSlotId, activePageId } = useActiveConfigValues();
    const schedulesQuery = useSchedules(activePageId, activeSlotId);
    const { data: schedules, isLoading, isError, error } = schedulesQuery;

    function onDragEnd(fromIndex: number, toIndex: number) {
        const newSchedules = [...(schedules ?? [])];
        // remove the item from the old position
        const [removed] = newSchedules.splice(fromIndex, 1);
        // insert the item into the new position
        newSchedules.splice(toIndex, 0, removed);
        queryClient.setQueryData(
            ['schedules', activePageId, activeSlotId],
            newSchedules
        );
    }

    /**
     * `useSchedules` hook remains disabled until the active page and slot are set.
     * As a result, isLoading could always remain true if the `useSchedules` hook fails to fetch.
     * To prevent this, extra checks are added.
     */
    if (activePageId && activeSlotId && isLoading)
        return (
            <div className="flex justify-center">
                <FaSpinner className="animate-spin" size={22} />;
            </div>
        );

    if (isError) {
        const defaultMessage = 'Some error has been occurred in slot widget';
        const message = error.response?.data?.message ?? defaultMessage;
        return (
            <div
                className="mb-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
                role="alert"
            >
                {message}
            </div>
        );
    }

    if (schedules?.length === 0)
        return <p className="text-center">No widgets</p>;

    return (
        <ReactDragListView
            handleSelector="#widget-list span.drag-handle"
            nodeSelector="#widget-list > li.list-item"
            onDragEnd={(from, to) => {
                onDragEnd(from, to);
            }}
        >
            <ol id="widget-list">
                {schedules?.map((schedule) => (
                    <li key={schedule.widgetId} className="list-item">
                        <SlotWidgetItem schedule={schedule} />
                    </li>
                ))}
            </ol>
        </ReactDragListView>
    );
}
