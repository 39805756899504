// 3P Dependencies
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

// Redux Dependencies
import { RootState } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveWidgetId } from '../app/slices/activeWidgetSlice';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { Schedule } from '../interfaces/Schedule';

/**
 * A hook to fetch schedules for a given page and slot
 * @param pageSlug The slug of the page to fetch schedules for
 * @param slotId The slotId to fetch schedules for
 */
export function useSchedules(pageSlug: string | null, slotId: string | null) {
    const dispatch = useDispatch();
    const { widgetId } = useSelector((state: RootState) => state.activeWidget);
    return useQuery<Schedule[], AxiosError<ErrorResponse>>(
        ['schedules', pageSlug, slotId],
        async () => {
            const url = `/schedule/slugs/${pageSlug}/slots/${slotId}?deviceType=mobile`;
            const response = await HTTPClient.get<Schedule[]>({ url });
            return response.data;
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            enabled: !!pageSlug && !!slotId,
            onSuccess(schedules) {
                // If `widgetId` is not set, set it to the first schedule's widgetId
                const value = schedules?.at(0)?.widgetId;
                if (!widgetId && value) dispatch(setActiveWidgetId(value));
            },
        }
    );
}
