import { useActiveConfigValues } from '../../../hooks/useActiveConfigValues';
import { useUpdateWidgetSettings } from '../../../hooks/useUpdateWidgetSettings';
import format from 'date-fns/format';
import {
    addDraftedSlot,
    DraftedSlot,
} from '../../../app/slices/draftedSlotsSlice';
import { useDispatch } from 'react-redux';
import { ChangeEvent } from 'react';

/**
 * Returns the minimum date limits for both the start and end time pickers.
 * For the start time picker, the minimum date is the current date.
 * For the end time picker, the minimum date is the start time.
 */
function getMinDateLimits(currentStartTime?: string) {
    const startTime = format(new Date(), "yyyy-MM-dd'T'HH:mm");
    const currentStartTimeDate = currentStartTime
        ? new Date(currentStartTime)
        : new Date();
    const endTime = format(currentStartTimeDate, "yyyy-MM-dd'T'HH:mm");
    return { startTime, endTime };
}
export function WidgetSettings() {
    const dispatch = useDispatch();
    const { activeWidget, activePageId, activeSlotId } =
        useActiveConfigValues();
    const updateSettings = useUpdateWidgetSettings();
    const limits = getMinDateLimits(activeWidget?.startTime);

    function onScheduleChange(
        event: ChangeEvent<HTMLInputElement>,
        key: 'startTime' | 'endTime'
    ) {
        const value = new Date(event.target.value);
        const dateValue = format(value, 'yyyy-MM-dd HH:mm:ss');
        updateSettings(key, dateValue);
        const draftedSlot: DraftedSlot = {
            id: activeSlotId,
            pageId: activePageId,
        };
        dispatch(addDraftedSlot(draftedSlot));
    }

    return (
        <div className="space-y-5">
            <h2 className="text-md font-semibold">Schedule Settings</h2>
            <div className="flex flex-col space-y-3">
                <label className="text-sm font-semibold" htmlFor="start-time">
                    Start Time
                </label>
                <input
                    className="rounded-lg border p-2.5 text-sm"
                    id="start-time"
                    min={limits.startTime}
                    name="start-time"
                    type="datetime-local"
                    value={activeWidget?.startTime ?? ''}
                    onChange={(event) => {
                        onScheduleChange(event, 'startTime');
                    }}
                />
            </div>
            <div className="flex flex-col space-y-3">
                <label className="text-sm font-semibold" htmlFor="end-time">
                    End Time
                </label>
                <input
                    className="rounded-lg border p-2.5 text-sm"
                    id="end-time"
                    min={limits.endTime}
                    name="end-time"
                    type="datetime-local"
                    value={activeWidget?.endTime ?? ''}
                    onChange={(event) => {
                        onScheduleChange(event, 'endTime');
                    }}
                />
            </div>
        </div>
    );
}
