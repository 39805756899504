import { FaSpinner } from 'react-icons/fa';
import { usePublishMutation } from '../../../hooks/usePublishMutation';
import { useActiveConfigValues } from '../../../hooks/useActiveConfigValues';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { DraftedSlot } from '../../../app/slices/draftedSlotsSlice';

export function PublishSlot() {
    const { activeWidget, activeSlotId, activePageId } =
        useActiveConfigValues();
    const publishMutation = usePublishMutation();
    const draftedSlots = useSelector(
        ({ draftedSlots }: RootState) => draftedSlots.slots
    );
    const draftedSlot: DraftedSlot = { id: activeSlotId, pageId: activePageId };
    const isDraftedSlot = draftedSlots.find(
        (slot) => draftedSlot.id && slot.pageId === draftedSlot.pageId
    );

    return (
        <button
            className="relative w-full items-center space-x-5 rounded-lg bg-red-600 py-2 px-4 text-white disabled:cursor-not-allowed disabled:opacity-50"
            disabled={
                publishMutation.isLoading || !activeWidget || !isDraftedSlot
            }
            type="button"
            onClick={() => {
                publishMutation.mutate();
            }}
        >
            {publishMutation.isLoading && (
                <FaSpinner className="absolute animate-spin" size={22} />
            )}
            Publish Slot
        </button>
    );
}
