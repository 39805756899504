var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Button.tsx
import { jsx } from "react/jsx-runtime";
var Button = (props) => {
  const defaultClass = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded";
  const fallbackText = "Button";
  const _a = props, { children = fallbackText, className = defaultClass, onClick } = _a, rest = __objRest(_a, ["children", "className", "onClick"]);
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      console.warn("Default Click");
    }
  };
  return /* @__PURE__ */ jsx(
    "button",
    __spreadProps(__spreadValues({
      onClick: handleClick,
      className
    }, rest), {
      children
    })
  );
};

// src/Modal.tsx
import { useEffect as useEffect2, useRef } from "react";
import { createPortal } from "react-dom";

// src/hooks/useClickAwayListener.tsx
import { useEffect } from "react";
function useClickAwayListener(callback, ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ref]);
}

// src/icons/CrossIcon.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
function CrossIcon() {
  return /* @__PURE__ */ jsx2(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      fill: "none",
      viewBox: "0 0 24 24",
      strokeWidth: 1.5,
      stroke: "currentColor",
      className: "h-6 w-6",
      children: /* @__PURE__ */ jsx2(
        "path",
        {
          strokeLinecap: "round",
          strokeLinejoin: "round",
          d: "M6 18L18 6M6 6l12 12"
        }
      )
    }
  );
}

// src/Modal.tsx
import { jsx as jsx3, jsxs } from "react/jsx-runtime";
var Modal = (props) => {
  const modalRef = useRef(null);
  const rootElement = document.querySelector("body");
  const { isOpen, children, setOpen } = props;
  useClickAwayListener(() => {
    setOpen(false);
  }, modalRef);
  useEffect2(() => {
    rootElement.style.overflow = "hidden";
    return () => {
      rootElement.style.overflow = "auto";
    };
  }, []);
  if (!isOpen)
    return null;
  return createPortal(
    /* @__PURE__ */ jsx3("div", { className: "fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-600/60", children: /* @__PURE__ */ jsxs(
      "div",
      {
        ref: modalRef,
        className: "relative w-full max-w-xl rounded-xl bg-white p-10",
        children: [
          /* @__PURE__ */ jsx3(
            "button",
            {
              type: "button",
              "aria-label": "close modal",
              className: "absolute top-2 right-2 rounded-full p-2 transition-colors duration-300 hover:bg-gray-300",
              onClick: () => setOpen(false),
              children: /* @__PURE__ */ jsx3(CrossIcon, {})
            }
          ),
          children
        ]
      }
    ) }),
    rootElement
  );
};
export {
  Button,
  Modal
};
