// 3P Dependencies
import { useQuery } from '@tanstack/react-query';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { Page } from '../interfaces/Page';

export function usePages() {
    return useQuery<Page[]>(
        ['pages'],
        async () => {
            const url = '/page/templates';
            const response = await HTTPClient.get<Page[]>({ url });
            return response.data;
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );
}
