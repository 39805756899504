import { createSlice } from '@reduxjs/toolkit';
import {
    ILogin,
    IUserAuthorization,
    IUserDetails,
} from '../../utils/models/user.model';
import { RootState } from '../store';

type InitialState = {
    authData: IUserAuthorization[] | null;
    loginData: ILogin | null;
    userData: IUserDetails[] | null;
};

const initialState: InitialState = {
    loginData: null,
    userData: null,
    authData: null,
};
const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        addLoginData: (state, { payload }) => {
            state.loginData = payload;
        },
        addUserAuthorizationDetails: (state, { payload }) => {
            state.authData = payload;
        },
        addUserDetails: (state, { payload }) => {
            state.userData = payload;
        },
    },
});

export const { addLoginData, addUserDetails, addUserAuthorizationDetails } =
    userSlice.actions;

export const getLoginData = (state: RootState) => {
    return state.userSlice.loginData;
};

export const getUserDetails = (state: RootState) => {
    return state.userSlice.userData;
};

export const getAuthorizationDetails = (state: RootState) => {
    return state.userSlice.authData;
};

export default userSlice.reducer;
