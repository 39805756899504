// 3P Dependencies
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useActiveConfigValues } from './useActiveConfigValues';
import { useSchedules } from './useSchedules';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { useDispatch } from 'react-redux';
import { removeDraftedSlot } from '../app/slices/draftedSlotsSlice';

/**
 * A hook to publish the current slot's configuration.
 */
export function usePublishMutation() {
    const { activePageId, activeSlotId } = useActiveConfigValues();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const schedules = useSchedules(activePageId, activeSlotId);
    return useMutation<unknown, AxiosError<ErrorResponse>>(
        async () => {
            const url = '/schedule/widgets';
            const promises =
                schedules.data?.map((schedule) => {
                    const body = JSON.stringify(schedule);
                    return HTTPClient.post({ url, body });
                }) ?? [];
            await Promise.all(promises);
        },
        {
            async onSuccess() {
                const draftedSlot = { id: activeSlotId, pageId: activePageId };
                dispatch(removeDraftedSlot(draftedSlot));
                await queryClient.invalidateQueries([
                    'page-preview',
                    activePageId,
                ]);
                await queryClient.invalidateQueries([
                    'schedules',
                    activePageId,
                    activeSlotId,
                ]);
                alert('Published!');
            },
            onError(error) {
                const defaultMessage = 'Something went wrong';
                const message = error.response?.data?.message ?? defaultMessage;
                alert(message);
            },
        }
    );
}
