import { useDispatch, useSelector } from 'react-redux';
import { useActiveConfigValues } from '../../../hooks/useActiveConfigValues';
import format from 'date-fns/format';
import { setActiveDateTime } from '../../../app/slices/activeDateTimeSlice';
import { RootState } from '../../../app/store';

export function DateTimePicker() {
    const dispatch = useDispatch();
    const draftedSlots = useSelector(
        ({ draftedSlots }: RootState) => draftedSlots.slots
    );
    const { activeDateTime } = useActiveConfigValues();
    const formattedDateTime = format(activeDateTime, "yyy-MM-dd'T'HH:mm");

    return (
        <div className="mb-3 flex items-center space-x-5 border-b border-gray-300 pb-3">
            <label className="font-semibold" htmlFor="preview-date-time">
                Preview Time:
            </label>
            <input
                className="rounded-lg border p-2.5 text-sm"
                disabled={draftedSlots.length > 0}
                id="preview-date-time"
                min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                type="datetime-local"
                value={formattedDateTime}
                onChange={(event) => {
                    const value = event.target.value;
                    dispatch(setActiveDateTime(value));
                }}
            />
        </div>
    );
}
