// 3P Dependencies
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import format from 'date-fns/format';

// Hooks Dependencies
import { useActiveConfigValues } from './useActiveConfigValues';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { PagePreview } from '../interfaces/PagePreview';

/**
 * A hook to fetch the final page configuration
 */
export function usePagePreview() {
    const { activePageId, activeDateTime } = useActiveConfigValues();
    const formattedDateTime = format(activeDateTime, 'yyyy-MM-dd HH:mm:ss');
    return useQuery<PagePreview, AxiosError<ErrorResponse>>(
        ['page-preview', activePageId, formattedDateTime],
        async () => {
            const url = `/page/${activePageId}?deviceType=mobile&dateTime=${formattedDateTime}`;
            const response = await HTTPClient.get<PagePreview>({ url });
            return response.data;
        },
        {
            enabled: !!activePageId,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );
}
