// 3P Dependencies
import { useState } from 'react';

// Component Dependencies
import { Button } from 'ui';
import { Modal } from 'ui';

// Hooks Dependencies
import { useCreatePageMutation } from '../../hooks/useCreatePageTemplateMutuation';

/**
 * Remove all special characters from slug
 * @param slug - page slug
 */
function getCleanSlug(slug: string) {
    return slug.replace(/[^a-zA-Z0-9]/g, '-');
}

const CreatePage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [pageName, setPageName] = useState('');
    const [pageSlug, setPageSlug] = useState('');
    const createPageTemplate = useCreatePageMutation();
    const _data = {
        pageName,
        slug: getCleanSlug(pageSlug),
        status: 'publish',
        tags: [],
        slots: ['slot-1', 'slot-2', 'slot-3'],
    };

    return (
        <>
            <Button
                className="rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => setIsOpen(true)}
            >
                Add new page
            </Button>
            <Modal isOpen={isOpen} setOpen={setIsOpen}>
                <div>
                    <label
                        className="mb-2 block text-sm font-medium text-gray-900"
                        htmlFor="pageName"
                    >
                        Name
                    </label>
                    <input
                        required
                        className="block w-full rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        id="pageName"
                        placeholder="Enter Name"
                        type="text"
                        value={pageName}
                        onChange={(e) => {
                            setPageName(e.target.value);
                        }}
                    />
                </div>

                <div className="mt-6">
                    <label
                        className="mb-2 block text-sm font-medium text-gray-900"
                        htmlFor="pageSlug"
                    >
                        Slug
                    </label>
                    <input
                        required
                        className="block w-full rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        id="pageSlug"
                        placeholder="Enter Slug"
                        type="text"
                        value={pageSlug}
                        onChange={(e) => {
                            setPageSlug(e.target.value);
                        }}
                    />
                </div>

                <div className="mt-6">
                    <Button
                        className="mr-2 mb-2 rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            createPageTemplate.mutate(_data);
                        }}
                    >
                        Build New Page
                    </Button>
                </div>

                {createPageTemplate.isError ? (
                    <div
                        className="mb-4 mt-3 mb-3 rounded-lg bg-red-100 py-3 px-4 text-sm text-red-700"
                        role="alert"
                    >
                        {createPageTemplate?.error?.response?.data?.message ||
                            'Some error has been occurred in create page '}
                    </div>
                ) : null}
            </Modal>
        </>
    );
};

export default CreatePage;
