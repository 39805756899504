import { useActiveConfigValues } from '../../../../hooks/useActiveConfigValues';
import { Image } from '@newco-widgets/types';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';

export function SingleImagePicker({ propName }: BasePickerProps) {
    const { activeWidget } = useActiveConfigValues();
    const defaultValue = activeWidget?.widgetData[propName] as Image;
    const image = {
        src: defaultValue?.src ?? '',
        alt: defaultValue?.alt ?? '',
    };

    const updateProperty = useUpdatePropInConfiguration(propName);
    return (
        <div className="space-y-3">
            <label
                className="inline-block text-sm font-semibold"
                htmlFor="image-single-src"
            >
                Image Source
            </label>
            <input
                className="block w-full rounded-lg border p-2.5"
                id="image-single-src"
                placeholder="Enter image's alt text here..."
                value={image.src}
                onChange={(event) => {
                    image.src = event.target.value;
                    updateProperty({ ...image });
                }}
            />
            <label
                className="inline-block text-sm font-semibold"
                htmlFor="image-single-alt"
            >
                Image Alt Text
            </label>
            <input
                className="block w-full rounded-lg border p-2.5"
                id="image-single-alt"
                placeholder="Enter image's alt text here..."
                value={image.alt}
                onChange={(event) => {
                    image.alt = event.target.value;
                    updateProperty({ ...image });
                }}
            />
        </div>
    );
}
