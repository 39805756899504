import { WidgetList } from './components/WidgetList';
import { WidgetSettingsPanel } from './components/WidgetSettingsPanel';
import { Preview } from './components/Preview';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveDateTime } from '../../app/slices/activeDateTimeSlice';
import format from 'date-fns/format';

export function Editor() {
    const dispatch = useDispatch();

    /**
     * This effect will make sure to set the `activeDateTime` to the current date time
     * when the page is loaded
     */
    useEffect(() => {
        const presentDateTime = format(new Date(), 'yyyy-MM-dd HH:mm:00');
        dispatch(setActiveDateTime(presentDateTime));
    }, [dispatch]);

    return (
        <div className="mx-auto grid h-screen w-screen max-w-[1900px] grid-cols-10 gap-x-5">
            <div className="col-span-2 p-5 shadow-md">
                <WidgetList />
            </div>
            <div className="col-span-6 pt-5 pb-5">
                <Preview />
            </div>
            <div className="col-span-2 p-5 shadow-md">
                <WidgetSettingsPanel />
            </div>
        </div>
    );
}
