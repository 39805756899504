import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../app/store';
import { useSchedules } from './useSchedules';

/**
 * A utility hook to quickly access the active configuration values like the active page, slot, and widget.
 */
export function useActiveConfigValues() {
    const { pageId = '', slotId = '' } = useParams();
    const { widgetId } = useSelector((state: RootState) => state.activeWidget);
    const activeDateTime = useSelector(
        ({ dateTime }: RootState) => dateTime.activeDateTime
    );
    const { data: schedules } = useSchedules(pageId, slotId);

    const activeWidget = schedules?.find(
        (schedule) => schedule.widgetId === widgetId
    );

    return {
        activeWidget,
        activeDateTime: new Date(activeDateTime),
        activePageId: pageId,
        activeSlotId: slotId,
        activeWidgetId: widgetId,
    };
}
