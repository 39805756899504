// 3P Dependencies
import { useMutation, useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';
import { AxiosError } from 'axios';

// Hooks Dependencies
import { useActiveConfigValues } from './useActiveConfigValues';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { Schedule } from '../interfaces/Schedule';
import { ErrorResponse } from '../interfaces/ErrorResponse';

export type CreateScheduleBody = Omit<Schedule, 'scheduleId'>;
interface CreateScheduleMutationProps {
    onSuccess?: () => void;
}

/**
 * A hook to create a new schedule.
 */
export function useCreateScheduleMutation(props?: CreateScheduleMutationProps) {
    const queryClient = useQueryClient();
    const { activeDateTime, activePageId } = useActiveConfigValues();
    const formattedDateTime = format(activeDateTime, 'yyyy-MM-dd HH:mm:ss');

    return useMutation<Schedule, AxiosError<ErrorResponse>, CreateScheduleBody>(
        async (schedule) => {
            const body = JSON.stringify(schedule);
            const url = '/schedule/widgets';
            const response = await HTTPClient.post<Schedule>({ url, body });
            return response.data;
        },
        {
            async onSuccess(schedule) {
                // update the cache with the new schedule
                queryClient.setQueryData<Schedule[]>(
                    ['schedules', schedule.slug, schedule.slotId],
                    (schedules) => {
                        if (!schedules) return;
                        return [...schedules, schedule];
                    }
                );
                await queryClient.invalidateQueries([
                    'page-preview',
                    activePageId,
                    formattedDateTime,
                ]);
                if (props?.onSuccess) props?.onSuccess();
            },
        }
    );
}
