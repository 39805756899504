import { FaSpinner } from 'react-icons/fa';
import { WidgetPickerMapping } from '@newco-widgets/types';
import { FormEvent, useState } from 'react';
import {
    CreateScheduleBody,
    useCreateScheduleMutation,
} from '../../../../hooks/useCreateScheduleMutation';
import { v4 as uuid } from 'uuid';
import format from 'date-fns/format';
import { useActiveConfigValues } from '../../../../hooks/useActiveConfigValues';
import { FormScheduleFields } from './FormScheduleFields';

interface AddNewWidgetFormBodyProps {
    setShowModal: (value: boolean) => void;
}

export function AddNewWidgetFormBody({
    setShowModal,
}: AddNewWidgetFormBodyProps) {
    const [isDefault, setIsDefault] = useState(false);
    const values = useActiveConfigValues();
    const createScheduleMutation = useCreateScheduleMutation({
        onSuccess: () => setShowModal(false),
    });
    const widgets = Object.keys(WidgetPickerMapping);
    const errorMessage =
        createScheduleMutation.error?.response?.data.message ?? '';

    function onFormSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const widgetName = formData.get('widget');
        const startTime = new Date(formData.get('start-time') as string);
        const endTime = new Date(formData.get('end-time') as string);
        let startTimeString = format(startTime, 'yyyy-MM-dd HH:mm:ss');
        let endTimeString = format(endTime, 'yyyy-MM-dd HH:mm:ss');
        const isDefaultWidget = formData.get('is-default-widget') === 'on';
        if (isDefaultWidget) {
            startTimeString = '';
            endTimeString = '';
        }
        const requestBody: CreateScheduleBody = {
            widgetId: `${widgetName}+${uuid()}`,
            slotId: values.activeSlotId ?? '',
            startTime: startTimeString,
            endTime: endTimeString,
            slug: values.activePageId ?? '',
            widgetData: {},
            isDefault: isDefaultWidget,
        };

        createScheduleMutation.mutate(requestBody);
    }
    return (
        <form className="space-y-5" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-3">
                <label className="text-sm font-semibold" htmlFor="widget">
                    Widget
                </label>
                <select
                    required
                    className="flex-1 rounded-lg border p-2.5 text-sm capitalize"
                    id="widget"
                    name="widget"
                >
                    {widgets.map((widget) => (
                        <option key={widget} value={widget}>
                            {widget}
                        </option>
                    ))}
                </select>
            </div>
            {!isDefault && <FormScheduleFields />}
            <div className="flex space-x-5">
                <label
                    className="text-sm font-semibold"
                    htmlFor="is-default-widget"
                >
                    Is Default Widget?
                </label>
                <input
                    className="rounded-lg border p-2.5 text-sm"
                    id="is-default-widget"
                    name="is-default-widget"
                    type="checkbox"
                    onChange={() => {
                        setIsDefault(!isDefault);
                    }}
                />
            </div>
            <div>
                <button
                    className="relative w-full items-center space-x-5 rounded-lg bg-blue-600 py-2 px-4 text-white"
                    type="submit"
                >
                    {createScheduleMutation.isLoading && (
                        <FaSpinner
                            className="absolute animate-spin"
                            size={22}
                        />
                    )}
                    Add Widget
                </button>
                <p className="mt-2 text-sm text-red-600">
                    {createScheduleMutation.isError ? '*' + errorMessage : ''}
                    {/*Zero Width HTML character, in-order to avoid the layout shift*/}
                    &#8203;
                </p>
            </div>
        </form>
    );
}
