// Widget Dependencies
import { WidgetType } from '@newco-widgets/types';

// Selectors
import { ActiveSlotSelector } from './selectors/ActiveSlotSelector';
import { ActivePageSelector } from './selectors/ActivePageSelector';

// Component Dependencies
import { SlotWidgets } from './SlotWidgets';
import { AddNewWidgetSection } from './AddNewWidget';
import { AddNewSlotSection } from './AddNewSlot';
import { PublishSlot } from './PublishSlot';

/**
 * Extracts the widget name from the widget id
 * @param widgetId The widget id is a string in the format of `widgetType+uuid`.
 * `widgetType` is used to determine the widget to render. All the mapping is
 * done with the `WidgetType`.
 */
export function getWidgetName(widgetId: string): WidgetType {
    const [widgetType] = widgetId.split('+');
    return widgetType as WidgetType;
}

export function WidgetList() {
    return (
        <div className="flex max-h-44 flex-col justify-between space-y-20">
            <div className="space-y-5">
                <h2 className="text-center text-xl font-bold">Widget List</h2>
                <ActivePageSelector />
                <ActiveSlotSelector />
                <SlotWidgets />
            </div>
            <div className="space-y-5">
                <PublishSlot />
                <AddNewWidgetSection />
                <AddNewSlotSection />
            </div>
        </div>
    );
}
