// 3P Dependencies
import { useNavigate } from 'react-router-dom';

// Component Dependencies
import { Button } from 'ui';

const SelectPage = (props: any) => {
    const navigate = useNavigate();
    const navigatePage = () => {
        navigate(`/page/editor/${props.activePageId}`);
    };

    return (
        <Button
            className="rounded-full border border-gray-300 px-5 py-2.5 text-sm font-medium hover:bg-gray-100"
            onClick={navigatePage}
        >
            Edit existing page
        </Button>
    );
};

export default SelectPage;
