export function getBaseUrl() {
    const env = process.env.NODE_ENV || 'development';
    // update to production host
    if (env === 'production' || env === 'test')
        // TODO; replace me
        return 'http://34.110.251.67/newco/internal/v1';
    return 'http://34.110.251.67/newco/internal/v1';
}

export function createEndpoint(endpoint: string) {
    const host = getBaseUrl();
    return `${host}${endpoint}`;
}
