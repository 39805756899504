// 3P Dependencies
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

// Hooks Dependencies
import { useActiveConfigValues } from './useActiveConfigValues';
import { useBasePath } from './useBasePath';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { PageTemplate } from '../interfaces/PageTemplate';

/**
 * A hook to fetch the page template for a given page slug.
 */
export function usePageTemplate() {
    const { activePageId } = useActiveConfigValues();
    const navigate = useNavigate();
    const basePath = useBasePath();
    return useQuery<PageTemplate, AxiosError<ErrorResponse>>(
        ['page-template', activePageId],
        async () => {
            const url = `/page/templates/${activePageId}`;
            const response = await HTTPClient.get<PageTemplate>({ url });
            return response.data;
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            enabled: !!activePageId,
            onSuccess(template) {
                const slug = template.slug;
                const slotId = template.slots.at(0);
                if (slug && slotId && basePath) {
                    navigate(`${basePath}/${slug}/${slotId}`, {
                        replace: true,
                    });
                }
            },
        }
    );
}
