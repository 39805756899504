import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { worker } from './mocks/browser';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './errorBoundary/ErrorBoundary';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import './styles/tailwind.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Initialize the msw worker, wait for the service worker registration to resolve, then mount
if (process.env.NODE_ENV === 'development') {
    worker.start();
}
const queryClient = new QueryClient();
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Provider store={store}>
                <BrowserRouter>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>
);
