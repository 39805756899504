// Redux Dependencies
import { useDispatch } from 'react-redux';
import { setActiveWidgetId } from '../../../app/slices/activeWidgetSlice';

// Hooks Dependencies
import { useActiveConfigValues } from '../../../hooks/useActiveConfigValues';

// Components Dependencies
import { getWidgetName } from './WidgetList';
import difference from 'date-fns/differenceInSeconds';

// Type Dependencies
import { Schedule } from '../../../interfaces/Schedule';

interface SlotWidgetItemProps {
    schedule: Schedule;
}

/**
 * A utility function to determine if a schedule is active.
 */
export function isActiveSchedule(activeDate: Date, schedule: Schedule) {
    const startTime = new Date(schedule.startTime);
    const endTime = new Date(schedule.endTime);
    return (
        difference(activeDate, startTime) >= 0 &&
        difference(endTime, activeDate) >= 0
    );
}

export function SlotWidgetItem({ schedule }: SlotWidgetItemProps) {
    const dispatch = useDispatch();
    const { activeWidgetId, activeDateTime } = useActiveConfigValues();
    const isActive = isActiveSchedule(activeDateTime, schedule);
    return (
        <button
            className={`mb-5 flex w-full items-center space-x-5 rounded-lg bg-blue-600 py-2 px-4 text-white ${
                activeWidgetId === schedule.widgetId ? 'bg-green-600' : ''
            }`}
            type="button"
            onClick={() => {
                dispatch(setActiveWidgetId(schedule.widgetId));
            }}
        >
            <span className="drag-handle cursor-move">:::</span>
            <span className="flex items-center space-x-1">
                <span className="capitalize">
                    {getWidgetName(schedule.widgetId)}
                </span>
                {schedule.isDefault && (
                    <span className="text-xs">(default)</span>
                )}
                {!schedule.isDefault && isActive && (
                    <span className="text-xs">(active)</span>
                )}
            </span>
        </button>
    );
}
