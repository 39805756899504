import { usePageTemplate } from '../../../../hooks/usePageTemplate';
import { FormEvent } from 'react';
import { Modal } from 'ui';
import { FaSpinner } from 'react-icons/fa';
import { useUpdatePageTemplateMutation } from '../../../../hooks/useUpdatePageTemplateMutation';

interface AddNewSlotModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

export function AddNewSlotModal({
    showModal,
    setShowModal,
}: AddNewSlotModalProps) {
    const { data: pageTemplate } = usePageTemplate();
    const rootElement = document.querySelector('body');
    const updatePageTemplate = useUpdatePageTemplateMutation({
        onSuccess() {
            setShowModal(false);
        },
    });
    const errorMessage = updatePageTemplate.error?.response?.data.message ?? '';

    if (!rootElement || !showModal) return null;

    function onFormSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!pageTemplate) return;
        const formData = new FormData(event.currentTarget);
        const slotName = (formData.get('slot-name') as string) ?? '';
        const pageTemplateCopy = { ...pageTemplate };
        if (pageTemplateCopy.slots.includes(slotName.toLowerCase())) return;
        pageTemplateCopy.slots.push(slotName.toLowerCase());
        updatePageTemplate.mutate(pageTemplateCopy);
    }

    return (
        <Modal isOpen={showModal} setOpen={setShowModal}>
            <div className="space-y-7">
                <h1 className="text-xl font-semibold">Add New Slot</h1>
                <form className="flex flex-col" onSubmit={onFormSubmit}>
                    <div className="mb-10 flex flex-col space-y-3">
                        <label className="text-sm font-semibold">
                            Slot Name
                        </label>
                        <input
                            required
                            className="rounded-lg border p-2.5 text-sm"
                            name="slot-name"
                            placeholder="Enter slot name..."
                            type="text"
                        />
                    </div>
                    <button
                        className="relative w-full items-center space-x-5 rounded-lg bg-blue-600 py-2 px-4 text-white"
                        type="submit"
                    >
                        {updatePageTemplate.isLoading && (
                            <FaSpinner
                                className="absolute animate-spin"
                                size={22}
                            />
                        )}
                        Add Slot
                    </button>
                    <p className="mt-2 text-sm text-red-600">
                        {updatePageTemplate.isError ? '*' + errorMessage : ''}
                        &#8203;
                    </p>
                </form>
            </div>
        </Modal>
    );
}
