// 3P Dependencies
import { useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';

// Hooks Dependencies
import { useActiveConfigValues } from '../hooks/useActiveConfigValues';

// Type Dependencies
import { PagePreview } from '../interfaces/PagePreview';
import { Schedule } from '../interfaces/Schedule';
import { useDispatch } from 'react-redux';
import { addDraftedSlot, DraftedSlot } from '../app/slices/draftedSlotsSlice';

export interface BasePickerProps {
    propName: string;
}

/**
 * A hook to update the schedule config when a picker is changed
 */
export function useUpdatePropInConfiguration(propName: string) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { activeSlotId, activeWidgetId, activePageId, activeDateTime } =
        useActiveConfigValues();
    const formattedDateTime = format(activeDateTime, 'yyyy-MM-dd HH:mm:ss');

    return function mutate(newValue: unknown) {
        // update the schedule's cache
        queryClient.setQueryData<Schedule[]>(
            ['schedules', activePageId, activeSlotId],
            (schedules) => {
                if (!schedules) return;
                const newSchedules = structuredClone(schedules) as Schedule[];
                const widget = newSchedules.find(
                    (schedule) => schedule.widgetId === activeWidgetId
                );
                if (!widget) return;
                widget.widgetData[propName] = newValue;
                return newSchedules;
            }
        );
        // update the page-preview config's cache if widget is in preview
        queryClient.setQueryData<PagePreview>(
            ['page-preview', activePageId, formattedDateTime],
            (pages) => {
                if (!pages || !activeSlotId) return;
                const newPagePreview = structuredClone(pages) as PagePreview;
                const widget = newPagePreview.widgets[activeSlotId];
                if (widget.widgetId === activeWidgetId) {
                    widget.widgetData[propName] = newValue;
                }
                return newPagePreview;
            }
        );
        const draftedSlot: DraftedSlot = {
            id: activeSlotId,
            pageId: activePageId,
        };
        dispatch(addDraftedSlot(draftedSlot));
    };
}
