import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import format from 'date-fns/format';

export interface ActiveDateTimeState {
    activeDateTime: string;
}

const initialState: ActiveDateTimeState = {
    // date with seconds set to 00
    activeDateTime: format(new Date(), 'yyyy-MM-dd HH:mm:00'),
};

export const activeDateTimeSlice = createSlice({
    name: 'activeDateTime',
    initialState,
    reducers: {
        setActiveDateTime: (state, action: PayloadAction<string>) => {
            state.activeDateTime = action.payload;
        },
    },
});

export const { setActiveDateTime } = activeDateTimeSlice.actions;

export default activeDateTimeSlice.reducer;
