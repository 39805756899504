import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface DraftedSlot {
    id: string;
    pageId: string;
}
export interface DraftedSlotsState {
    slots: DraftedSlot[];
}

const initialState: DraftedSlotsState = {
    slots: [],
};

export const draftedSlotsSlice = createSlice({
    name: 'draftedSlots',
    initialState,
    reducers: {
        addDraftedSlot: (state, action: PayloadAction<DraftedSlot>) => {
            const alreadyExists = state.slots.find(
                (slot) =>
                    slot.id === action.payload.id &&
                    slot.pageId === action.payload.pageId
            );
            if (alreadyExists) return;
            state.slots.push(action.payload);
        },
        removeDraftedSlot: (state, action: PayloadAction<DraftedSlot>) => {
            state.slots = state.slots.filter(
                (slot) =>
                    slot.id !== action.payload.id &&
                    slot.pageId !== action.payload.pageId
            );
        },
    },
});

export const { addDraftedSlot, removeDraftedSlot } = draftedSlotsSlice.actions;

export default draftedSlotsSlice.reducer;
