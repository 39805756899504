import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

/**
 * The state for the active widget is managed by this slice.
 * The state for active page and slot is managed through the dynamic URL.
 */
export interface ActiveWidgetState {
    /**
     * The id of the active widget
     */
    widgetId: string | null;
}

const initialState: ActiveWidgetState = {
    widgetId: null,
};

export const activeWidgetSlice = createSlice({
    name: 'activeWidget',
    initialState,
    reducers: {
        setActiveWidgetId: (state, action: PayloadAction<string>) => {
            state.widgetId = action.payload;
        },
    },
});

export const { setActiveWidgetId } = activeWidgetSlice.actions;

export default activeWidgetSlice.reducer;
