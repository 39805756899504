import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    ILogin,
    IUserAuthorization,
    IUserDetails,
} from '../../utils/models/user.model';

const baseQuery = fetchBaseQuery({
    baseUrl: '/',
});

export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: baseQuery,
    tagTypes: ['Login'],
    endpoints: (builder) => ({
        login: builder.mutation<ILogin, Record<string, never>>({
            query: (body) => ({
                headers: {
                    'Content-type': 'application/json',
                },
                url: `login`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Login'],
        }),
        userInfo: builder.query<IUserDetails, number>({
            query: (id: number) => `user/${id}`,
        }),
        userAuthorization: builder.mutation<
            IUserAuthorization,
            Record<string, unknown>
        >({
            query: (body) => ({
                headers: {
                    'Content-type': 'application/json',
                },
                url: `user/authorize`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Login'],
        }),
    }),
});

export const {
    useLoginMutation,
    useUserInfoQuery,
    useUserAuthorizationMutation,
} = loginApi;
