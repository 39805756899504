// Component Dependencies
import { SelectPage } from '../Pages';
import CreatePage from '../Pages';

// hooks
import { usePages } from '../../hooks/usePages';

// Image Dependencies
import bgImage from '../../assets/cloud-bg.jpg';
import { FaSpinner } from 'react-icons/fa';

const Home = () => {
    const { data: pages, isLoading } = usePages();

    // if (isLoading)
    //     return (
    //         <div className="flex justify-center">
    //             <FaSpinner className="animate-spin" size={22} />;
    //         </div>
    //     );
    return (
        <div className="relative overflow-auto bg-gray-50">
            <div className="flex flex-row space-x-4">
                <div
                    className="min-h-screen basis-1/3 bg-cover"
                    style={{ backgroundImage: `url(${bgImage})` }}
                ></div>
                <div className="basis-2/3">
                    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6">
                        <div className="relative bg-white px-6 pt-8 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
                            <h1 className="mb-2 text-2xl text-gray-900">
                                Dashboard
                            </h1>
                            <p className="mb-6 text-sm text-gray-500">
                                From this section, we can either create a new
                                page or edit existing page
                            </p>
                            <div className="flex flex-col">
                                <div className="mt-4 flex items-center space-x-2">
                                    <CreatePage />
                                    {isLoading && (
                                        <div className="">
                                            <FaSpinner
                                                className="animate-spin"
                                                size={22}
                                            />
                                        </div>
                                    )}
                                    {pages && pages.length !== 0 ? (
                                        <SelectPage
                                            activePageId={pages[0]?.slug}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
