import { useActiveConfigValues } from '../../../../hooks/useActiveConfigValues';
import {
    BasePickerProps,
    useUpdatePropInConfiguration,
} from '../../../../utils/pickerHelper';
export function MultiLinePicker({ propName }: BasePickerProps) {
    const updateProperty = useUpdatePropInConfiguration(propName);
    const { activeWidget } = useActiveConfigValues();
    const value = (activeWidget?.widgetData[propName] as string) ?? '';

    return (
        <textarea
            className="block w-full rounded-lg border p-2.5"
            id={propName}
            placeholder="text"
            value={value}
            onChange={(event) => {
                const newValue = event.target.value;
                updateProperty(newValue);
            }}
        />
    );
}
