import { useActiveConfigValues } from '../../../hooks/useActiveConfigValues';
import { Picker, WidgetPickerMapping } from '@newco-widgets/types';
import { FC } from 'react';
import {
    MultiImagePicker,
    MultiLinePicker,
    SingleImagePicker,
    SingleLinePicker,
    TogglePicker,
} from './Pickers';
import { BasePickerProps } from '../../../utils/pickerHelper';
import { getWidgetName } from './WidgetList';
import { WidgetSettings } from './WidgetSettings';

/**
 * A mapping of component to render for each picker type.
 */
const PickerWidgetMapping: Record<Picker, FC<BasePickerProps>> = {
    [Picker.MultiImagePicker]: MultiImagePicker,
    [Picker.TogglePicker]: TogglePicker,
    [Picker.MultiLinePicker]: MultiLinePicker,
    [Picker.SingleImagePicker]: SingleImagePicker,
    [Picker.SingleLinePicker]: SingleLinePicker,
};

export function WidgetSettingsPanel() {
    const { activeWidget } = useActiveConfigValues();
    const activeWidgetType = getWidgetName(activeWidget?.widgetId ?? '');
    // `pickerPropsMapping` is a mapping of the widget's props and the picker to use for each prop.
    const pickerPropsMapping = WidgetPickerMapping[activeWidgetType] ?? {};
    // `widgetProps` contains all the props for the widget.
    const widgetProps = Object.keys(pickerPropsMapping) ?? [];

    if (!activeWidget) {
        return (
            <div>
                <h2 className="mb-10 text-center text-xl font-bold">
                    Settings
                </h2>
                <p className="text-center">No Widget Selected</p>
            </div>
        );
    }

    return (
        <div>
            <h2 className="mb-10 text-center text-xl font-bold">
                Settings ({activeWidgetType})
            </h2>
            {!activeWidget.isDefault && <WidgetSettings />}
            <div className="mt-5 space-y-5">
                <h2 className="text-md font-semibold">Properties</h2>
                {widgetProps.map((propName) => {
                    const pickerName = pickerPropsMapping[propName] as Picker;
                    // `Settings` specifies the component to render for the provided `pickerName`.
                    const Settings = PickerWidgetMapping[pickerName];
                    if (!(pickerName in Picker) || !Settings) return null;
                    return (
                        <div key={propName} className="space-y-3">
                            <label
                                className="block text-sm font-semibold capitalize"
                                htmlFor={propName}
                            >
                                {propName}
                            </label>
                            <Settings propName={propName} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
