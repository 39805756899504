// 3P Dependencies
import { useNavigate, useParams } from 'react-router-dom';

// Hooks Dependencies
import { usePages } from '../../../../hooks/usePages';
import { useBasePath } from '../../../../hooks/useBasePath';
import { useQueryClient } from '@tanstack/react-query';

export function ActivePageSelector() {
    const navigate = useNavigate();
    const params = useParams();
    const basePath = useBasePath();
    const { data: pages } = usePages();
    const queryClient = useQueryClient();

    return (
        <div className="flex items-center space-x-3">
            <label className="font-semibold">Active Page: </label>
            <select
                className="flex-1 rounded-lg border px-2.5 py-2 text-sm"
                value={params.pageId}
                onChange={async (event) => {
                    const pageId = event.target.value;
                    navigate(`${basePath}/${pageId}/${params.slotId}`);
                    await queryClient.invalidateQueries([
                        'page-template',
                        pageId,
                    ]);
                }}
            >
                {pages?.map((page) => (
                    <option key={page.slug} value={page.slug}>
                        {page.pageName}
                    </option>
                ))}
            </select>
        </div>
    );
}
