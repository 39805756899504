// 3P Dependencies
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

// Hooks Dependencies
import { useActiveConfigValues } from './useActiveConfigValues';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { PageTemplate } from '../interfaces/PageTemplate';

interface UpdatePageTemplateProps {
    onSuccess: () => void;
}

export function useUpdatePageTemplateMutation(props?: UpdatePageTemplateProps) {
    const { activePageId } = useActiveConfigValues();
    const queryClient = useQueryClient();
    return useMutation<unknown, AxiosError<ErrorResponse>, PageTemplate>(
        async (pageTemplate: PageTemplate) => {
            const url = `page/templates/${activePageId}`;
            // temporary fix as backend expects flags to be lowercase but some
            // data is stored in uppercase
            pageTemplate.status =
                pageTemplate.status.toLowerCase() as PageTemplate['status'];
            const body = JSON.stringify(pageTemplate);
            return HTTPClient.put({ url, body });
        },
        {
            async onSuccess() {
                await queryClient.invalidateQueries([
                    'page-template',
                    activePageId,
                ]);
                props?.onSuccess?.();
            },
        }
    );
}
