// 3P Dependencies
import { Routes, Route } from 'react-router-dom';

// Component Dependencies
import Home from './components/Home/Home';
import { Editor } from './components/Editor';

// Styles Dependencies
import 'ui/styles.css';
import { HTTPClient } from '@newco/ui-utils';
import { getBaseUrl } from './utils/fetchHelper';

HTTPClient.init({
    defaultHeaders: {
        'ngrok-skip-browser-warning': 'Bearer',
    },
    baseURL: getBaseUrl(),
});

function App() {
    return (
        <div>
            <Routes>
                <Route element={<Home />} path="/" />
                <Route path="/page/editor/:pageId">
                    <Route index element={<Editor />} />
                    <Route element={<Editor />} path=":slotId" />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
