import { faker } from '@faker-js/faker';

// ===================== LOGIN =================================== //

export const LOGIN_INFO: any[] = [];
function Login() {
    return {
        accessToken: faker.random.alphaNumeric(50),
        status: 'success',
        userId: faker.datatype.number(1000),
    };
}
LOGIN_INFO.push(Login());

// ===================== AUTHORIZE =================================== //

export const AUTHORIZE_INFO: any[] = [];
function Authorize() {
    return {
        access: faker.datatype.boolean(),
        companyId: faker.datatype.number(100000),
    };
}
AUTHORIZE_INFO.push(Authorize());

// ===================== USER INFO =================================== //

export const USER_INFO: any[] = [];
function UserInfo() {
    return {
        userId: faker.datatype.number(1000),
        emailId: faker.internet.email(),
        companyId: faker.datatype.number(100000),
        roleId: faker.datatype.number(10),
        accessValues: faker.helpers.arrayElements([1, 2, 3, 4, 5, 6, 7, 8]),
        status: faker.datatype.number(10),
        createdAt: faker.datatype.datetime(),
        access: faker.datatype.boolean(),
    };
}
USER_INFO.push(UserInfo());
