// 3P Dependencies
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

// Utils Dependencies
import { HTTPClient } from '@newco/ui-utils';

// Type Dependencies
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { PagePreview } from '../interfaces/PagePreview';

/**
 * A hook to create a new schedule.
 */
export function useCreatePageMutation() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation<PagePreview, AxiosError<ErrorResponse>>(
        async (data) => {
            const body = JSON.stringify(data);
            const url = '/page/templates';
            const response = await HTTPClient.post<PagePreview>({ url, body });
            return response.data;
        },
        {
            async onSuccess(data, variables: any) {
                // update the cache with the new schedule
                await queryClient.invalidateQueries(['pages']);
                navigate(
                    `/page/editor/${variables.slug}/${variables.slots[0]}`
                );
            },
        }
    );
}
