// 3P Dependencies
import { useQueryClient } from '@tanstack/react-query';

// Hooks Dependencies
import { useActiveConfigValues } from './useActiveConfigValues';

// Type Dependencies
import { Schedule } from '../interfaces/Schedule';

/**
 * A hook to update any specific property of a schedule.
 */
export function useUpdateWidgetSettings() {
    const queryClient = useQueryClient();
    const { activePageId, activeSlotId, activeWidgetId } =
        useActiveConfigValues();
    return function updateProperty(propertyName: string, value: unknown) {
        queryClient.setQueryData<Schedule[]>(
            ['schedules', activePageId, activeSlotId],
            (schedules) => {
                if (!schedules) return;
                return schedules.map((schedule) => {
                    if (schedule.widgetId !== activeWidgetId) return schedule;
                    return {
                        ...schedule,
                        [propertyName]: value,
                    };
                });
            }
        );
    };
}
